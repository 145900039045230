<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Modulo de Abastecimiento - Listar</strong>
          </CCardHeader>
          <CCardBody>

            <b-row>
              <b-col sm="12" md="9">
           
              </b-col>

      
              <b-col sm="6" md="2">
                <b-form-group label=".">
                  <b-input-group>
                  <b-form-input type="search" v-model="search" class="form-control"></b-form-input>
                  <b-input-group-append>
                    <b-button variant="primary" @click="ListCatering"><b-icon icon="search"></b-icon></b-button>
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>

              <b-col sm="6" md="1">
                <b-form-group label=".">
                  <b-link  v-if="Permission('CateringAdd')" class="btn form-control btn-primary"  :to="{ path: '/abastecimiento/nuevo' }" append ><i class="fas fa-file"></i></b-link>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="table-responsive mt-3 height-table">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="3%" class="text-center">#</th>
                    <th width="8%" class="text-center">Fecha</th>
                    <th width="12%" class="text-center">Codigo</th>
                    <th width="50%" class="text-center">Almacen</th>
                    <th width="10%" class="text-center">Usuario</th>
                    <th width="5%" class="text-center">Estado</th>
                    <th width="7%" class="text-center">Acciones</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in data_table" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.date }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.warehouse_name }}</td>
                    <td class="text-left"> {{ item.user }}</td>
                    <td class="text-center">
                      <b-badge v-if="item.state == 1" variant="success">Activo</b-badge>
                      <b-badge v-if="item.state == 0" variant="danger">Anulado</b-badge>
                    </td>
                    <td class="text-center">
                      <b-dropdown bloque size="sm" text="Acciones" right>
                        <b-dropdown-item v-if="Permission('InputEdit')" @click="EditCatering(item.id_catering)" >Editar</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('InputView')"  @click="ViewCatering(item.id_catering)" >Ver</b-dropdown-item>
                        <b-dropdown-item v-if="Permission('InputDelete')" @click="ConfirmDeleteCatering(item.id_catering)">Eliminar</b-dropdown-item>
                      </b-dropdown>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage"  v-on:input="ListCatering"  :total-rows="rows"  :per-page="perPage" align="center" ></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>

import vSelect from "vue-select";
import 'vue-select/dist/vue-select.css';
import "vue-select/src/scss/vue-select.scss";


const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import CodeToName from "@/assets/js/CodeToName";
var moment = require("moment");

export default {
  name: "EntradaList",
  components:{
      vSelect,
  },
  data() {
    return {
      module: 'Catering',
      perPage: 15,
      currentPage: 1,
      rows: 0,
      search: "",
      data_table: [],

      id_provider:0,
      to:moment(new Date()).local().format("YYYY-MM-DD"),
      from:moment().subtract(30, 'days').local().format("YYYY-MM-DD"),
      search: "",
      providers: [],
      provider:null,
      errors:{
        to:false,
        from:false,
      }
    };
  },
  mounted() {
    this.ListCatering();
  },
  methods: {
    ListCatering,
    EditCatering,
    ViewCatering,
    ConfirmDeleteCatering,
    DeleteCatering,
    Permission,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ListCatering() {
  
  let search = this.search == "" ? "all" : this.search;
  let me = this;
  let url = this.url_base + "catering/list/" + search + "?page=" + this.currentPage;
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token,module: this.module,role: 1},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        me.rows = response.data.result.total;
        me.data_table = response.data.result.data;
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error', text: 'A ocurrido un error', timer: 3000,})
    });
}

// Editar usuario
function EditCatering(id_catering) {
  this.$router.push({
    name: "CateringEdit",
    params: { id_catering: je.encrypt(id_catering) },
  });
}

// Ver Usuario
function ViewCatering(id_catering) {
  this.$router.push({
    name: "CateringView",
    params: { id_catering: je.encrypt(id_catering) },
  });
}

// Confirmar eliminar
function ConfirmDeleteCatering(id_catering) {
  Swal.fire({
    title: "Esta seguro de eliminar el abastecimiento?",
    text: "No podrás revertir esto!",
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#3085d6",
    cancelButtonColor: "#d33",
    confirmButtonText: "Si, Estoy de acuerdo!",
  }).then((result) => {
    if (result.value) {
      this.DeleteCatering(id_catering);
    }
  });
}

// eliminar usuario
function DeleteCatering(id_catering) {
  let me = this;
  let url = this.url_base + "catering/delete/" + id_catering;
  axios({
    method: "delete",
    url: url,
    headers: {token: this.token, module: this.module,role: 4},
  })
    .then(function (response) {
      if (response.data.status == 200) {
        const index = me.data_table.map(item => item.id_catering).indexOf(response.data.result.id_catering);
        me.data_table.splice(index, 1);
        Swal.fire({ icon: 'success', text: response.data.message, timer: 3000,})
      } else {
        Swal.fire({ icon: 'error', text: response.data.message, timer: 3000,})
      }
    })
    .catch((error) => {
      Swal.fire({ icon: 'error',  text: 'A ocurrido un error',  timer: 2000, })
    });
}

// permisos
function Permission(module_permission) {
  let user_permissions = window.localStorage.getItem("user_permissions");
  user_permissions = JSON.parse(JSON.parse(je.decrypt(user_permissions)));
  if (user_permissions.indexOf(module_permission) > -1) {
    return true;
  } else {
    return false;
  }
}
</script>
